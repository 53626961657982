@import "minima";


//////////////// GLOBAL ////////////////////////

.minimum-height {
  min-height: 50vh !important;
}

main a, main a:hover, main a:visited {
  color: inherit;
}

footer a, footer a:hover, footer a:visited {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  max-width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  height: 100% !important;
}

main {
  background-color: rgb(36, 36, 36);
}

main, footer, .seo-footer {
  font-family: 'Raleway', Arial;
  overflow-x: hidden;
  overflow-y:hidden;
  width: 100%;
}

.page-content {
  padding: 0;
}

h2, h3 {
  font-weight: 300 !important;
  letter-spacing: 0.1em;
}

a:hover {
  text-decoration: none;
}

body {
  background-color: rgb(36, 36, 36);
}

.anchors {
  display: block;
  position: relative;
  top: -20vh;
  visibility: hidden;
}

////////////////////////////////////////////////



//////////////// HEADER ////////////////////////

a.nav-link {
  font-size: 1.1em;
}

#navigation_bar {
  min-height: 12vh;
  background-color: rgb(36, 36, 36) !important;
}

.button_header {
    margin-right: 0.4em;
    padding: 0.9em;
    background-color: rgb(36, 36, 36);
    border-radius: 50px;
    cursor: pointer;
    border: none;
    text-align: center;
    text-decoration: none;
  }

.button_header:hover {
  background-color: rgb(0,0,0);
}

.navbar-brand {
  width: 10vw;
  height: 10vh;
}

.logo_nav {
  object-fit: contain !important;
  height: 10vh;
  padding: 5px;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .navbar-brand {
    width: 25vw;
    height: 10vh;
  }

  .logo_nav {
    object-fit: contain !important;

  }


  .button_header {
    padding: 0.5em;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .navbar-brand {
    width: 25vw;
  }

  .logo_nav {
    object-fit: contain !important;
  }

}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-brand {
    width: 25vw;
  }

  .logo_nav {
    object-fit: contain !important;
  }

  .button_header {
    padding: 0.5em;
  }

}

////////////////////////////////////////////////



/////////// HOMEPAGE SCREEN BLOC ///////////////

.wallpaperHomePage{
  width: 100%;
  height: 100%;
  padding-bottom: 23%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}

.fondDegradeLogo {
  object-fit: contain !important;
}

.sliderLogoContainer {
  width: 35vw !important;
}

.bloc-slider-homepage {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -70%);
  opacity: 1;
  z-index: 99;
  width: 80%;
}

.lottie-homepageScreen {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -35%);
  opacity: 1;
  width: 8%;
  display: inline-block;
  z-index: 99;
  height: 10%;
}

a.button_homepageScreen {
  z-index: 99;
  padding: 0.9em;
  background-color: rgb(36, 36, 36);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

a.button_homepageScreen:hover {
  background-color: rgb(0,0,0);
}

.titleText {
  letter-spacing: 0.2em;
  font-weight: 300;
  line-height: 1.5em;
  z-index: 99;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .fondDegradeLogo {
    object-fit: contain !important;
  }


  .sliderLogoContainer {
    width: 55vw !important;
  }

  .lottie-homepageScreen {
    width: 20%;
    height: 10%;
  }

  a.button_homepageScreen {
    padding: 0.6em;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {


  .fondDegradeLogo {
    object-fit: contain !important;
  }

  .sliderLogoContainer {
    width: 55vw !important;
  }

  .lottie-homepageScreen {
    width: 20%;
    height: 10%;

  }

  a.button_homepageScreen {
    padding: 0.6em;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

  .fondDegradeLogo {
    object-fit: contain !important;
  }
  .lottie-homepageScreen {
    width: 20%;
    height: 10%;
  }

  .sliderLogoContainer {
    width: 55vw !important;
  }

  a.button_homepageScreen {
    padding: 0.6em;
  }
}

////////////////////////////////////////////////



//////////////// PHOTO TEXT BLOC ////////////////////////

.button-contact {
  padding: 0.7em;
  background-color: rgb(36, 36, 36);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.button-contact:hover {
  background-color: rgb(0,0,0);
}

.photoTitleText {
  text-align: center;
  padding: 3%;
  margin-bottom: 0;
  background-color: rgb(0,0,0);
  border-top: 1px solid;
  border-bottom: 1px solid;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .titleText {
    padding: 5%;
  }
  #photoTextLottie {
    width: 100% !important;
    margin: auto;
  }
  .button-contact {
    padding: 0.6em;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

  .titleText {
    padding: 5%;
  }
  #photoTextLottie {
    width: 100% !important;
    margin: auto;
  }
  .button-contact {
    padding: 0.6em;
  }

}

////////////////////////////////////////////////



//////////////// GALLERY BLOC //////////////////

.thumbnail-modal {
  cursor: pointer;
  padding: 0 !important;
}

////////////////////////////////////////////////



//////////////// MAPS BLOC  ////////////////////

.icon-maps {
  width: 30%;
}

.map-content > iframe {
  height: 100%;
  width: 100%;
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .icon-maps {
    width: 10%;
    margin: 0 auto !important;
  }

  .informations {
    margin: 0 auto !important;
  }

  .maps {
    height: 50vh;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

  .icon-maps {
    width: 10%;
    margin: 0 auto !important;
  }

  .informations {
    margin: 0 auto !important;
  }


  .maps {
    height: 50vh;
  }
}

////////////////////////////////////////////////

.homepage-double-screen {
  height: 100vh !important;
  background-color: rgb(36, 36, 36)
}

.logoContainer {
  width: 250px;
  height: 150px;
}

.logoDoubleScreen {
  object-fit: contain !important;
}

.halfScreen {
  width: 50% !important;
}

.halfScreen1 {
  border-right: 1px solid white;
}

a.button_homepageDoubleScreen {
  z-index: 99;
  padding: 0.9em;
  background-color: rgb(36, 36, 36);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

a.button_homepageDoubleScreen:hover {
  background-color: rgb(0,0,0);
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .logoContainer {
    width: 150px;
    height: 100px;
  }

  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }

  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }


}


@media (min-width: 576px) and (max-width: 767px) {
  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  .logoContainer {
    width: 150px;
    height: 100px;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }
  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }

}


// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .halfScreen {
    width: 100% !important;
    height: 50vh;
  }
  .logoContainer {
    width: 150px;
    height: 100px;
  }
  a.button_homepageDoubleScreen {
    padding: 0.6em;
  }
  .halfScreen2 {
    border-top: 1px solid white;
  }

  .halfScreen1 {
    border-right: none;
  }
}

///////////////////////////////////////////



///////////// SLIDER HOMEPAGE BLOC ////////

#myCarousel {
  height: 100% !important;
  width: 100% !important;
}

.carousel .carousel-inner, .carousel .carousel-item {
  width: 100%;
  height: 100%;
}

.carousel-item img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
  filter: brightness(70%);
}

.carousel-inner .carousel-item > img {
  -webkit-animation: thing 20s;
  -o-animation: thing 20s;
  animation: thing 20s;
}


@keyframes thing {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}


// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .carousel-item img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .carousel-item img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

////////////////////////////////////////////////



//////////////// OWL CAROUSEL BLOC /////////////

.owl-nav, owl-dots {
  display: none;
}

.owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

.owl-height {
  height: 300px !important;
}

////////////////////////////////////////////////



///////////// CONTACT FORM BLOC ////////////////

form {
  width: 70%;
}

@media only screen and (max-width: 800px) {
  form {
    width: 90%;
  }
}

////////////////////////////////////////////////



///////// PRODUCT CARDS BLOC /////////////////

.cards-container {
  display: flex;
  margin: 50px 0px;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.card-container {
  font-size: 1.1rem;
  width: 350px;
  height: 350px;
  background-size: cover;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 5px;
  margin: 25px 35px;
}

.card-location-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  word-wrap: break-word;
  margin-top: -15px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-location {
  background-color: rgb(36, 36, 36);
  max-width: 350px;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.card-location p {
  margin: 7px;
}

.card-price {
  background-color: rgb(0,0,0);
  position: absolute;
  bottom: 90px;
  box-sizing: border-box;
  left: -30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.card-price p {
  font-weight: bold;
  margin: 5px 15px;
}

.card-title {
  background-color: rgb(0,0,0);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  text-align: center;
  box-sizing: border-box;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title p {
  font-weight: bold;
  margin: 12px;
}

////////////////////////////////////////////////



///////// PRODUCT LANDING BLOC /////////////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;
  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-top: 5%;
    padding-right: 0!important;
    padding-left: 0 !important;
  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;
  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .productMainContainer {
    width: 80% !important;
    margin-top: 20vh;

  }

  .productInfoContainer {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .productContactCard {
    width: 75%;
  }
}

////////////////////////////////////////////////



///////// BANDEAU IMAGE EN-TETE PAGE ///////////

.bandeau {
  min-height: 46vh;
  background-color: rgb(0,0,0);
}

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .bandeau {
    min-height: 45vh;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .bandeau {
    min-height: 45vh;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .bandeau {
    min-height: 45vh;
  }
}

////////////////////////////////////////////////



//////////// SERVICES LIST BLOC /////////////

.img-services-list {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .img-services-list {
    min-height: 30vh;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .img-services-list {
    min-height: 30vh;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .img-services-list {
    min-height: 30vh;
  }
}

////////////////////////////////////////////////



//////////// SERVICES GALLERY BLOC /////////////

a.link-services {
  text-decoration: none;
}

.title-services {
  color: black;
  background-color: white;
  padding: 6%;
  border-radius: 10px;
  font-size: 1.1em;
}

.services_gallery_card {
  border: 1px solid lightgray;
  border: 1px solid lightgray;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  min-height: 25vh;
}

.services_icon_card {
  width: 1.6em;
}

#servicesButton {
  width: 100%;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .services_gallery_card {
    padding: 3em 3em !important;
  }
  .services_icon_card {
    width: 3em;
    height: 3em;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .services_gallery_card {
    width: 40%;
  }
}

////////////////////////////////////////////////



////////////// MENTIONS LEGALES ////////////////

.containersMentionLegales {
  margin: 5% auto;
}

////////////////////////////////////////////////



///////////////////// COOKIES //////////////////

.termsfeed-com---palette-light .cc-nb-okagree,
.termsfeed-com---palette-light .cc-nb-reject {
  background-color: black !important;
}

////////////////////////////////////////////////



//////////////// CTA BLOC //////////////////////


.cta_banner {
  min-height: 30vh;
  padding-top: 3em;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .cta_banner {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .cta_banner {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .cta_banner {
    background-attachment: fixed;
  }
}



////////////////////////////////////////////////



/////// ACCOMPAGNEMENT TEXT BLOC //////////////

.title-accompagnement {
  text-transform: uppercase;
}


// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .accompagnement_text {
    background-attachment: fixed;
  }
}



////////////////////////////////////////////////



////////// DOUBLE IMAGES TEXT BLOC  ////////////

.double-images {
  background-color: rgb(36, 36, 36);
  padding-top: 5%;
  padding-bottom: 5%;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

  .double-images {
  padding-top: 0;
  padding-bottom: 0;
  }

  .text-double-images {
    order: 2;
    padding: 10% !important;
  }

  .first-image {
    order: 1;
  }

  .second-image {
    order: 3;
  }

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

  .double-images {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text-double-images {
    order: 2;
    padding: 10% !important;
  }

  .first-image {
    order: 1;
  }

  .second-image {
    order: 3;
  }
}

////////////////////////////////////////////////



////////////////// FOOTER //////////////////////

a.recherches_button {
  background-color: rgb(36, 36, 36);
}

a.recherches_button:hover {
  background-color: rgb(0,0,0);
}

footer {
  display: block;
  padding: 5% 0;
  width: 100%;
  background: rgb(99, 43, 49);
}

.footerContainer {
  position: relative;
}

.divContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerDiv {
  margin: auto 3%;
}

.footerMainLogo { // A modifier pour ajouter un cercle derrière le logo
  width: 8em;
}

#footerDivMainLogo {
  width: 10%;
  margin-left: 2px;
}

#footerDivIcons {
  width: 15%;
  text-align: center;
}

.footerIcon {
  filter: invert(100%);
  width: 40px;
  margin: auto 3%;
  background: white;
  border-radius: 50%;
}

a.mention_footer_link {
  text-decoration: none;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  footer {
    height: 96vh;
  }
  .divContainer {
    display: block;
  }
  .footerDiv{
    font-size: 14px;
    position: relative;
    margin: 15% auto;
    text-align: center;
  }
  .footerMainLogo {
    width: 35% !important;
  }
  #footerDivMainLogo {
    width: 100%;
  }
  #footerDivIcons {
    width: 100%;
    text-align: center;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  footer {
    height: 96vh;
  }
  .divContainer {
    display: block;
  }
  .footerDiv{
    font-size: 14px;
    position: relative;
    margin: 15% auto;
    text-align: center;
  }
  .footerMainLogo {
    width: 35% !important;
  }
  #footerDivMainLogo {
    width: 100%;
  }
  #footerDivIcons {
    width: 100%;
    text-align: center;
  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  #footerDivMainLogo {
    width: 20%;
  }
}

////////////////////////////////////////////////



.instagram-media {
  height: 500px;
}

._2p3a {
  width: 800px !important;
}



///////////////// BLOC PICTOCARD ///////////////

svg {
  width: 30%;
  height: 30%;
  margin: 0 auto;
  color: rgb(36, 36, 36);
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .picto_card {
    background-attachment: fixed;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .picto_card {
    background-attachment: fixed;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .picto_card {
    background-attachment: fixed;
  }
}




////////////////////////////////////////////////



///////////////// PRODUCT CARDS ///////////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .productCard {
    width: 300px !important;
    height: 300px !important;
  }
}

////////////////////////////////////////////////



///////////////// SEO MAIN /////////////////////

.logo_card {
  width: 50% !important;
}

.button_seo {
  padding: 0.4em;
  background-color: rgb(36, 36, 36);
  border-radius: 50px;
  cursor: pointer;
  border: none;
  text-align: center;
  text-decoration: none;
}

.button_seo:hover {
  background-color: rgb(0,0,0);
}

////////////////////////////////////////////////


//////////////////////////////////////////////////////////
///////////////////// ANIMATIONS /////////////////////////
//////////////////////////////////////////////////////////


////////////// ANIMATIONS JS ///////////////////

.animated-border-button {
  position: relative;
  display: inline-block;
  background-color: rgb(36, 36, 36);
}

.animated-border-button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgb(36, 36, 36);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.animated-border-button:hover:after {
  visibility: visible;
  transform: scaleX(1);
}


////////////////////////////////////////////////



.item > img {
  border-radius: 10px;
}



//////////////// GROW EFFECT ///////////////////

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: translate(2%, -3%);
}

////////////////////////////////////////////////





//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////



//////////// Squelette media queries ///////////

// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {

}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {

}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {

}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {

}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {

}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}

////////////////////////////////////////////////
